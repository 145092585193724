import Image from 'next/image'
import React from 'react'

import withWindowDimensions from 'components/common/withWindowDimensions'
import { IconLink, iconLinksData } from 'components/LSRevamp/constant'
import { LoginProps, withLogin } from 'components/Login/withLogin'
import { trackClick } from 'scripts/segment'
import { pageName } from 'utils/events-utils'
import { navRoutes } from 'page_routes'

const LinkCard: React.FC<
  IconLink & Pick<LoginProps, 'handleSpotCounsellingFlow'>
> = (props) => {
  const { icon, text, handleSpotCounsellingFlow, widgetName } = props

  const handleStartNowClick = () => {
    if (text === 'Crack IELTS') {
      window.open(navRoutes.IELTS_CRASH_COURSE, '_self')
    } else if (handleSpotCounsellingFlow) {
      handleSpotCounsellingFlow()
    }

    trackClick(pageName.LeapScholarHomePage, {
      contentName: 'Start Now',
      widgetName: widgetName,
      widgetFormat: 'Banner',
      contentFormat: 'Link',
    })
  }

  return (
    <div
      className="max-w-180 lg:min-w-[272px] md:min-w-220 h-20 md:h-52 bg-pink-gradient flex justify-between md:flex-col items-center rounded-10 py-2 px-4 md:pt-8 md:pb-7 cursor-pointer"
      onClick={handleStartNowClick}
    >
      <div className="flex flex-col justify-between md:items-center">
        <div className="w-35px md:w-73 md:mb-[6px]">
          <Image
            src={icon}
            width={34}
            height={34}
            layout="responsive"
            alt="link-icon"
          />
        </div>
        <h3 className="text-xs font-bold md:text-font22">{text}</h3>
      </div>
      <div className="bg-white shrink-0 md:bg-transparent w-6 md:w-fit h-6 md:h-fit flex items-center justify-center rounded-half self-end md:self-center">
        <div className="flex items-center gap-2">
          <p className="hidden md:block font-bold text-primary">Start Now</p>
          <div className="w-2.5 md:w-3">
            <Image
              src="/assets/icons/LSRevamp/iconLinks/arrow-blue.png"
              height={9}
              width={10}
              layout="responsive"
              alt="arrow"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

interface IconLinksProps {
  isMobileSized?: boolean
}

const IconLinks: React.FC<
  IconLinksProps & Pick<LoginProps, 'handleSpotCounsellingFlow'>
> = ({ isMobileSized, handleSpotCounsellingFlow }) => {
  return (
    <>
      <div className="px-5 md:relative top-[220px] md:mb-[168px] z-100">
        <h2 className="text-center font-bold md:text-[36px] text-font24 md:max-w-[880px] mx-auto md:mt-[200px]">
          Find the right resources to get started with LeapScholar
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-5 md:gap-8 max-w-[370px] md:max-w-[880px] m-auto mt-8">
          {iconLinksData.map((el: IconLink, i: number) => {
            return (
              <LinkCard
                key={i}
                {...el}
                handleSpotCounsellingFlow={handleSpotCounsellingFlow}
              />
            )
          })}
        </div>
      </div>
      <div className="w-full">
        {isMobileSized ? (
          <Image
            src={'/assets/images/LSRevamp/mobile-skyline.png'}
            layout="responsive"
            height={30}
            width={100}
            alt="mobile-background"
          />
        ) : (
          <Image
            src={'/assets/images/LSRevamp/desktop-skyline.png'}
            layout="responsive"
            height={20}
            width={100}
            alt="desktop-background"
          />
        )}
      </div>
    </>
  )
}

export default withWindowDimensions(withLogin(IconLinks))
